import React, { Component } from 'react';

class FaqContent extends Component {
    render() {
        return (
            <section className="faq-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="faq-section-title-area section-title-padding">
                                <div className="section-title text-center">
                                    <h2>FREQUENTLY ASKED QUESTIONS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            {
                                left_faq_data.map((single_faq_data, i) => (
                                    <div className="single-faq-content" key={i}>
                                        <h3 className="faq-heading">{single_faq_data.single_faq_heading}</h3>
                                        {single_faq_data.yes_text && (<span className="feature-product-tags">{single_faq_data.yes_text}</span>)}
                                        <p>{single_faq_data.single_faq_text}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {
                                right_faq_data.map((single_faq_data, i) => (
                                    <div className="single-faq-content" key={i}>
                                        <h3 className="faq-heading">{single_faq_data.single_faq_heading}</h3>
                                        {single_faq_data.yes_text && (<span className="feature-product-tags">{single_faq_data.yes_text}</span>)}
                                        <p>{single_faq_data.single_faq_text}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default FaqContent;

// left faq data
const left_faq_data = [
    {
        single_faq_heading: "What is your key products?",
        single_faq_text: "Our key products is Tshirt and Polo Shirt.",
    },
    {
        single_faq_heading: "What is your strength?",
        single_faq_text: "We provide best quality products and deliver on time."
    },
    {
        single_faq_heading: "Do you have facilities to do printing and embroidery?",
        single_faq_text: "We have in house printing capabilities and outsourced embroidery solutions."
    },
    {
        single_faq_heading: "Have you got design or technical team?",
        yes_text: "Yes",
        single_faq_text: "we have both design and technical teams. We provide all kinds of help regarding design and development of products.",
    },
    {
        single_faq_heading: "What is lead time for the sample generation?",
        single_faq_text: "We require 7-10 days to generate samples."
    },
    {
        single_faq_heading: "Do you charge for developing samples?",
        yes_text: "No",
        single_faq_text: "it is complete free of charge."
    },

    {
        single_faq_heading: "What is the lead time for normal orders?",
        single_faq_text: "We require 60-90 days to complete deliveries with 60 days being the norm."
    },
    {
        single_faq_heading: "Do you charge for shipment?",
        single_faq_text: "We provide Free on Board delivery."
    },
]

// right faq data

const right_faq_data = [
    {
        single_faq_heading: "Can you develop our design or offer your own design?",
        yes_text: "Yes",
        single_faq_text: "we can do both."
    },

    {
        single_faq_heading: "What do you need to make a product quotation?",
        single_faq_text: "We require technical information of the product to prepare a product quotation."
    },

    {
        single_faq_heading: "Can you follow our required color?",
        yes_text: "Yes",
        single_faq_text: "we can provide your required color."
    },

    {
        single_faq_heading: "Do you have an inspection team?",
        yes_text: "Yes",
        single_faq_text: "We have an inspection team."
    },

    {
        single_faq_heading: "Do you charge for inspection?",
        single_faq_text: "We may charge or not charge as per our negotiation with the client."
    },

    {
        single_faq_heading: "Do you test your fabric?",
        single_faq_text: "If required by the client, we conduct relevant chemical tests on the fabric sample."
    },
]
