import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../utils/common';

class WhatClientSayAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }
    componentDidMount() {
        this.dataFetch();
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/team`)
            .then(res => {
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        let { data } = this.state
        return (
            <section className="what-client-says-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title section-title-padding text-center">
                                <h2>Our Team</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {Object.keys(data).length !== 0 &&
                            data.map(item => (
                                <div className="col-lg-4 col-md-6" key={item.id}>
                                    <div className="single-client-says text-center">
                                        <div className="client-img">
                                            <img src={API_URL + item.image} alt="TeamImage" />
                                        </div>
                                        <div className="client-short-description">
                                            <h4>{item.name}</h4>
                                            <h5>{item.job_title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        )
    }
}
export default WhatClientSayAll