import React, { Component } from 'react';
import exportCountry from "../../../assets/images/exports.png";
// country export data
const countries_exports_data = {
	section_title: "Countries We Export ",
	content: "For more than a decade, we have exported quality products to customers from across the world.",
	export_image: require("../../../assets/images/countries.png"),
	export_img_alt: "exports image",
};
class SingleCountryExport extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<section className="country-exports-area section-padding">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 offset-lg-1 col-md-12">
							<div className="section-title section-title-padding text-center country-export-section-title">
								<h2>{countries_exports_data.section_title}</h2>
								<p>{countries_exports_data.content}</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="exports-img">
								<img src={exportCountry} alt="country export" />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-8 offset-lg-2">
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-4">
									<div className="country-export-country-list">
										<ul className="export-country-items">
											<li>Poland</li>
											<li>Brazil</li>
											<li>Australia</li>
											<li>Mexico</li>
											<li>Dubai</li>
											<li>Slovenia</li>
											<li>New Zealand</li>

										</ul>
									</div>
								</div>

								<div className="col-lg-4 col-md-4 col-sm-4">
									<div className="country-export-country-list">
										<ul className="export-country-items">
											<li>Slovakia</li>
											<li>Sweden</li>
											<li>Czech Republic</li>
											<li>Russia</li>
											<li>UK</li>
											<li>Hungary</li>
											<li>Romania</li>

										</ul>
									</div>
								</div>

								<div className="col-lg-4 col-md-4 col-sm-4">
									<div className="country-export-country-list">
										<ul className="export-country-items">
											<li>France</li>
											<li>Qatar</li>
											<li>Italy</li>
											<li>Switzerland</li>
											<li>Germany</li>
											<li>Denmark</li>
											<li>Canada</li>

										</ul>
									</div>
								</div>


							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
export default SingleCountryExport;