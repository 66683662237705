import React from 'react';
const DepartmentBonian = () => {
    return (
        <section className="department-bonian-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title section-title-padding text-center">
                            <h2>Departments Of Bonian Knit Fashions Ltd.</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="bonian-department-chart">
                            <img src={bonian_data.img} alt="bonian department chart" />
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default DepartmentBonian;

// bonian department data
const bonian_data = {
    img: require("../../../../assets/images/bonian_departments.png")
}