import React, { Component } from 'react';
import HowWorkBreadcrumb from "../container/breadcrumb/HowWorkBreadcrumb";
import FactoryFacilities from "../container/howwework/facilities/FactoryFacilities";
import SetUsApart from "../container/howwework/setapart/SetUsApart";
import DepartmentBonian from "../container/howwework/facilities/DepartmentBonian";
import MoreInfoWhyUs from "../container/whyus/MoreInfoWhyUs";
import HowWorkSticky from "../container/HowWorkSticky";
import MobileSticky from "../container/MobileSticky";

class HowWeWork extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <HowWorkBreadcrumb />
                <SetUsApart />
                <FactoryFacilities />
                <DepartmentBonian />
                <MoreInfoWhyUs />
                <HowWorkSticky />
                <MobileSticky />
            </React.Fragment>
        )
    }

}
export default HowWeWork;