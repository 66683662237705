import React, { Component } from 'react';
import { API_URL } from './../../utils/common';
import Axios from 'axios';

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        contact: '',
        message: '',
        alert: '',
        isLoading: false,
        status: 0
    }
    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    submitHandler = event => {
        event.preventDefault();
        let { name, email, message } = this.state
        if (!name) {
            alert('Please enter your name!')
        } else if (!email) {
            alert('Please enter your email!')
        } else if (!message) {
            alert('Please enter message!')
        } else {
            this.setState({
                isLoading: true,
            })

            Axios.post(`${API_URL}admin/message.php?name=${name}&email=${email}&message=${message}&contact=''`)
                .then(res => {
                    console.log("res", res.data)
                    this.setState({
                        status: 1,
                        isLoading: false,
                        alert: 'Sent successfully.',
                        name: '',
                        email: '',
                        contact: '',
                        message: '',
                    })
                })
                .catch(err => {
                    console.log('err', err.response)
                    this.setState({
                        status: 1,
                        isLoading: false,
                        alert: 'Sent successfully.',
                        name: '',
                        email: '',
                        contact: '',
                        message: '',
                    })
                })
            setTimeout(() => {
                this.setState({
                    alert: '',
                    status: 0
                })
            }, 3000)
        }
    }
    render() {
        let { name, email, message, isLoading, alert, status } = this.state

        return (
            <React.Fragment>
                <div className="col-lg-6 col-md-12">
                    {alert &&
                        <div className={status === 1 ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {alert}
                        </div>}
                    <form onSubmit={this.submitHandler} className="contact-page-form">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="single-form-field-area">
                                    <input
                                        className="film-single-filed"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        onChange={this.changeHandler}
                                        value={name}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="single-form-field-area">
                                    <input
                                        className="film-single-filed"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        onChange={this.changeHandler}
                                        value={email}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="single-form-field-area">
                                    <textarea
                                        name="message"
                                        id="user_message"
                                        placeholder="Write your message"
                                        onChange={this.changeHandler}
                                        value={message}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="single-form-field-area text-right mt-3">
                                    <input className="theme-btn" type="submit" value="Send Message" disabled={isLoading} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }

}
export default ContactForm;