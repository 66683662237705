import React from 'react';
import Fade from 'react-reveal/Fade';
import quality_img_assurance from "../../../../assets/images/Quality_Assurance_and_Inspection.JPG";
const QualityIns = () => {
    return (
        <React.Fragment>
            <div className="col-lg-6 col-md-12">
                <div className="quality-insurance-area mer-quality-area">
                    <Fade up>
                        <div className="sub-section-title pb-4 quality-ins-sub-heading merc-quality-icon">
                            <i className={quality_data.icon}></i>
                        </div>
                        <div className="quality-inner-content block-heading-content">
                            <h3>Quality Assurance:</h3>
                            <p className="pb-4">{quality_data.first_text_one}</p>
                            <p>{quality_data.first_text_two}</p>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="col-lg-6 col-md-12">
                <div className="quality-ins-feature-img">
                    <img src={quality_img_assurance} alt="quality" />
                </div>
            </div>
        </React.Fragment>
    )
}
export default QualityIns;

// all data
const quality_data = {
    icon: "fa fa-trophy",
    first_text_one: "We have an expert team of Quality Management responsible for overseeing different legs of production. We maintain International Textile Standards through the 4 Point QC System and AQL 2.5. Our final inspection team checks before the final output is passed on for export. This extensive Quality Check across the whole production process helps us ensure supreme quality products."
}
