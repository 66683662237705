import React, { Component } from "react";
import aid from "../../../assets/images/fire/First_Aid_Compliance.jpg";
import fire_safety from "../../../assets/images/fire/Fire_safety_training.jpg";
import child_Care from "../../../assets/images/fire/child_care.JPG";
import traning from "../../../assets/images/fire/training.jpg";
import employee from "../../../assets/images/fire/employee.JPG";
import picnic from "../../../assets/images/fire/picnic.JPG";

import Axios from "axios";
import { API_URL } from "../../../utils/common";

class FireSafty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activites: [],
    };
  }

  componentDidMount() {
    Axios.get(`${API_URL}api/compliance`).then((res) => {
      this.setState({
        activites: res.data,
      });
    });
  }
  render() {
    return (
      <section className="fire-safety-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2">
              <div className="section-title section-title-padding text-center">
                <h2>Our Activities</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.activites.map((activity) => (
              <div className="col-lg-4 col-md-6 p-2" key={activity.id}>
                <div className="single-fire-safety-content">
                  <div className="fire-safety-img">
                    <img src={`${API_URL}${activity.image}`} alt="fire safety" />
                    <div
                      className="fire-safety-inner-content"
                      id="fire-safety-one"
                    >
                      <h3 className="fire-safety-title">{activity.title}</h3>
                      <p>
                        {activity.description}
                      </p>
                    </div>
                  </div>
                  <div className="fire-safety-content">
                    <h3 className="fire-safety-title">{activity.title}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
export default FireSafty;
