import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
const HowWorkSticky = () => {
    const [sticky, setSticky] = useState(300)

    useEffect(() => {
        $(window).scroll(function () {
            let footer = this.document.getElementById("footer_id").clientHeight;
            let document_height = $(document).height() - footer;
            if ($(window).scrollTop() > 300) {
                if ($(window.screen.height > 600)) {
                    setSticky($(window).scrollTop() / 9)
                }
                else {
                    setSticky($(window).scrollTop() / 6);
                }
            }
            else if ($(window).scrollTop() < 300) {
                setSticky(300)
            }
        })
    })

    return (
        <React.Fragment>
            <div className="sticky-btn-contact" style={{ top: sticky + 'px' }} id="desktop-sticky">
                <Link to={`${process.env.PUBLIC_URL}/contact`}>
                    Start A New Project
          </Link>
            </div>
        </React.Fragment>
    );
}
export default HowWorkSticky;
