import React, { Component } from 'react';

class CustomerLove extends Component {
    render() {
        return (
            <section className="customer-love-area section-padding section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title section-title-padding text-center">
                                <h2>{customer_love.section_title}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            customer_love.customer_love_group.map((single_customer, i) => (

                                <div className="col-lg-6 col-md-6 col-sm-6" key={i}>
                                    <div className="single-customer-love-quote">
                                        <div className="customer_love_img">
                                            <img src={single_customer.customer_love_icon} alt={single_customer.customer_love_icon_alt} />
                                        </div>
                                        <div className="customer-love-content">
                                            <h3>{single_customer.customer_heading}</h3>
                                            <p>{single_customer.customer_text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default CustomerLove;

// customer love data
const customer_love = {
    section_title: "Why our customers prefer us?",
    customer_love_group: [
        {
            customer_love_icon: require("../../../assets/images/customer_love/personalization.png"),
            customer_love_icon_alt: "customer love icon",
            customer_heading: "Personalized and Dedicated Support",
            customer_text: "We support our clients through all stages of design, production and export."
        },
        {
            customer_love_icon: require("../../../assets/images/customer_love/source.png"),
            customer_love_icon_alt: "customer love icon",
            customer_heading: "Extensive Sourcing Capabilities",
            customer_text: "Our strong sourcing network allows us to produce versatile products."
        },
        {
            customer_love_icon: require("../../../assets/images/customer_love/dedicated_quality.png"),
            customer_love_icon_alt: "customer love icon",
            customer_heading: "Dedicated Quality Control and Inspection Team",
            customer_text: "Our Quality Control and Inspection team ensures supreme quality production."
        },
        {
            customer_love_icon: require("../../../assets/images/customer_love/compliant.png"),
            customer_love_icon_alt: "customer love icon",
            customer_heading: "Fully Compliant Plant",
            customer_text: "“We provide facilities for workers and maintain fire safety, electric safety, building safety as per guidelines of Bangladesh Labor Law, BGMEA, Accord, ILO, BSCI."
        },
        {
            customer_love_icon: require("../../../assets/images/customer_love/ontime.png"),
            customer_love_icon_alt: "customer love icon",
            customer_heading: "On Time Delivery",
            customer_text: "We put extra emphasis on timely deliveries being made to our clients."
        },

        {
            customer_love_icon: require("../../../assets/images/customer_love/manufacturing_process.png"),
            customer_love_icon_alt: "13 + Years of Experience",
            customer_heading: "Organic Manufacturing",
            customer_text: "We have a separate provision for organic manufacturing as certified by GOTS guidelines."
        },

    ]
}