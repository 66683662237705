import React, { Component, Fragment } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../../utils/common';

class DemoCsr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }
    componentDidMount() {
        this.dataFetch();
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/csr`)
            .then(res => {
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        let { data } = this.state
        // csr heading content
        const csr_heading_data = {
            heading: "Social Responsibility",
            heading_text: "All businesses need to be respectful and aware of their social responsibilities. We provide support to educational institutions, come forth during national crisis and distribute blankets to people in need."
        }

        // get data
        let bg_section = '';
        let csr_content = '';
        if (Object.keys(data).length !== 0) {
            bg_section = data.map(item => (
                <div className="csr-large-img" key={item.id}>
                    <img src={API_URL + item.image} alt="BgImage" />
                </div>
            ))

            csr_content = data.map(item => (
                <div className="single-csr-thumbnail-content" key={item.id}>
                    <div className="csr-thumb">
                        <img src={API_URL + item.image} alt='ImageNotFound' onClick={() => this.setState({ bgImage: item.image })} />
                    </div>
                    <div className="csr-thumb-content">
                        <h4><Link to={`${process.env.PUBLIC_URL}/csr`}>{item.title}</Link></h4>

                        <p>{item.description.substr(0, 170)}</p>
                        <Link to={`${process.env.PUBLIC_URL}/csr`} className="read-more">Read More</Link>
                    </div>
                </div>
            ))
        }


        const options = {
            loop: true,
            autoplay: true,
            items: 1,
            nav: false,
            autoplayHoverPause: false,
            autoplayTimeout: 4000,
            dots: false,
            smartSpeed: 800,
            responsive: {
                0: {
                    items: 1
                },
                786: {
                    items: 1
                },
                1200: {
                    items: 1
                }
            }
        }

        const bgOptions = {
            loop: true,
            autoplay: true,
            items: 1,
            nav: false,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            autoplayHoverPause: false,
            dots: false,
            autoplayTimeout: 4000,
            smartSpeed: 800,

            responsive: {
                0: {
                    items: 1
                },
                786: {
                    items: 1
                },
                1200: {
                    items: 1
                }
            }
        }
        return (
            <Fragment>
                <div className="csr-main-heading section-padding-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-md-8 offset-lg-1 offset-md-2">
                                <div className="section-title text-center section-title-padding csr-section-title-content">
                                    <h2>{csr_heading_data.heading}</h2>
                                    <p>{csr_heading_data.heading_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="csr-area section-padding-bottom">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-lg-6 col-md-12 no-padding non-padding">
                                <OwlCarousel className="owl-carousel owl-theme" {...bgOptions}>
                                    {bg_section}
                                </OwlCarousel>
                                <div className="view-all-csr-home text-right">
                                    <Link to={`${process.env.PUBLIC_URL}/csr`} className="view-all-btn ml-3 csr-view-all-btn">View All</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="csr-slider-area-home">

                                    <OwlCarousel
                                        className="owl-carousel owl-theme"
                                        {...options}
                                    >
                                        {csr_content}
                                    </OwlCarousel>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default DemoCsr;