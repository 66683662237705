import React, { Component } from 'react';
// import single widget
import Logo from "../components/container/widget/Logo";
import WidgetMenu from "../components/container/widget/WidgetMenu";
import Address from "../components/container/widget/Address";
import FooterBottom from "../components/container/widget/FooterBottom";

const social_link = [
    {
        social_icon: "fa fa-facebook",
        social_class: "facebook-color",
        social_link: "https://www.facebook.com/Bonian-Knit-Fashions-Ltd-158300321043000/"
    },
    {
        social_icon: "fa fa-youtube-play",
        social_class: "youtube-color",
        social_link: "https://www.youtube.com/"
    },
    {
        social_icon: "fa fa-linkedin",
        social_class: "linkedin-color",
        social_link: "https://www.linkedin.com/company/bonian-knit-fashions-ltd/"
    },
]

const get_social_data = social_link.map((single_social, i) => {
    return (
        <li key={i}><a href={single_social.social_link} className={single_social.social_class} target="_blank"><i className={single_social.social_icon}></i></a></li>
    )
})
class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="footer-area section-padding" id="footer_id">
                    <div className="container">
                        <div className="footer-shape-social">
                            <ul>
                                {get_social_data}
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <Logo />
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <Address />
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <WidgetMenu />

                            </div>
                        </div>
                    </div>
                </div>
                <FooterBottom />
            </React.Fragment>
        )
    }
}
export default Footer;
