
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';
// import animation file
import '../../../assets/css/animate.min.css';

// bonian team member data
const team_member_data = [
    {
        advisor_img: require('../../../assets/images/team_slider/simple.JPG'),
        advisor_img_alt: "Bonian teams",
        advisor_designation: "Sample Design and Development",
        advisor_description: "We help our clients with innovative ideas regarding design and development of the product. "
    },
    {
        advisor_img: require('../../../assets/images/team_slider/cbs.JPG'),
        advisor_img_alt: "Bonian teams",
        advisor_designation: "Quality Assurance and Inspection",
        advisor_description: "We ensure regular quality checks during different stages of production and move on to export after the final inspection."
    },
    {
        advisor_img: require('../../../assets/images/team_slider/shewing.JPG'),
        advisor_img_alt: "Bonian teams",
        advisor_designation: "Sewing Department",
        advisor_description: "The heart of our company, the sewing population is engaged in bulk sewing of products that you require. "
    },
]

const options = {
    loop: true,
    autoplay: true,
    nav: true,
    mouseDrag: true,
    autoplayHoverPause: false,
    responsiveClass: true,
    smartSpeed: 1000,
    autoplayTimeout: 2000,
    dots: false,
    navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items: 1
        },
        786: {
            items: 1
        },
        1200: {
            items: 1
        }
    }
}

const get_member = team_member_data.map((single_member, i) => {
    return (
        <div className="single-slider-advisor" key={i} style={{ backgroundImage: `url(${single_member.advisor_img})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="advisor-team-member-content">
                            <h2>{single_member.advisor_designation}</h2>
                            <p>{single_member.advisor_description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

class TeamSlider extends Component {
    render() {
        return (
            <section className="advisor-team-member-area">
                <OwlCarousel
                    className="advisor-slider owl-carousel owl-theme"
                    {...options}
                >
                    {get_member}
                </OwlCarousel>
            </section>
        )
    }
}
export default TeamSlider;

