import React, { Component } from "react";

class Responsible extends Component {
  render() {
    return (
      <section className="eco-friendly-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="row">
                {sus_logo.map((single_sus_logo, i) => (
                  <div className="col-lg-6 col-md-6 p-4  col-6" key={i}>
                    <div className="single-sustainability-logo-content">
                      <div className="sus-logo-img">
                        <img
                          src={single_sus_logo.logo}
                          alt={single_sus_logo.logo_alt}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="section-title">
                <h2>{eco_friendly_data.title}</h2>
                <h5 className="pt-4 pb-5">{eco_friendly_data.sub_heading}</h5>
                <div className="feature-item-list eco-friendly-list">
                  <ul>
                    {eco_friendly_data.eco_features.map((single_feature, i) => (
                      <li key={i}>
                        <i
                          className="fa fa-check-square-o"
                          aria-hidden="true"
                        ></i>
                        {single_feature.eco_feature_name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Responsible;

const sus_logo = [
  {
    logo: require("../../../assets/images/Sustainability/1.png"),
    logo_alt: "sustainability logo",
  },
  {
    logo: require("../../../assets/images/Sustainability/3.png"),
    logo_alt: "sustainability logo",
  },
  {
    logo: require("../../../assets/images/Sustainability/2.png"),
    logo_alt: "sustainability logo",
  },
  {
    logo: require("../../../assets/images/Sustainability/4.png"),
    logo_alt: "sustainability logo",
  },
];

// eco friendly data
const eco_friendly_data = {
  title: "Responsible Sourcing and Sustainability",
  sub_heading: "",
  eco_img: require("../../../assets/images/Sustainability/fashion_fabri.png"),
  eco_img_alt: "eco image",
  eco_features: [
    { eco_feature_name: "Cutting Down on Wastages of Fabrics" },
    { eco_feature_name: "Disposal of Leftover Wastage/Scraps" },
    { eco_feature_name: "Reducing Energy Consumption" },
    { eco_feature_name: "Innovations of Production Systems" },
    { eco_feature_name: "Eco Friendly System" },
    { eco_feature_name: "Reduction of Packaging Materials" },
  ],
};
