import React, { Component } from 'react';
import Axios from 'axios';
import $ from 'jquery';
import { API_URL } from '../../../utils/common';

class DownloadCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            detail: {}
        }
    }
    componentDidMount() {
        this.dataFetch();

        $(window).on('load', function () {
            // single download content height
            function useSameHeight(el) {
                var max = 0;
                el.css('height', 'auto');
                el.each(function () {
                    var c = this;
                    max = Math.max(max, $(c).height());
                }).height(max);
                return el;
            }
            $(window).resize(function () {
                useSameHeight($('.single-certificate-inner-content a'));
            }).resize();
        });

    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/certificate`)
            .then(res => {
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        let { data } = this.state
        return (
            <section className="download-certificate-area section-padding section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title text-center section-title-padding">
                                <h2>Download Certificate</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {Object.keys(data).length !== 0 &&
                            data.map(item => <div className="col-lg-3 col-sm-2 col-md-4" key={item.id}>
                                <div className="single-certificate-inner-content">
                                    <div className="certificate-download-btn-text">
                                        <a href={`${API_URL + item.image}`} download><i className="fa fa-download" aria-hidden="true"></i> {item.name}</a>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </section>
        )
    }
}
export default DownloadCertificate