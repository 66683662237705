import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import $ from "jquery";

// get data 
class MachineriesList extends Component {
    // same height bonian glance
    componentDidMount() {
        function useSameHeight(el) {
            var max = 0;
            el.css('height', 'auto');
            el.each(function () {
                max = Math.max(max, $(this).height());
            }).height(max);
            return el;
        }
        $(window).resize(function () {
            useSameHeight($('.single-machineries-wrapper'));
        }).resize();
    }
    render() {
        return (
            <section className="machinerieslist-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title section-title-padding text-center relative">
                                <h2 className="text-white">Machineries List</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            Machineries_Data.map((single_machineries, i) => (
                                <Fade up key={i}>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-machineries-wrapper">
                                            <div className="single-machineries-content text-center">
                                                <span>{single_machineries.name}</span>
                                                <h3>{single_machineries.set}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            ))
                        }
                    </div>
                </div>
            </section>
        )
    }
}
export default MachineriesList;


// machineries data
const Machineries_Data = [
    { name: "No. of sewing machines ", set: "310" }, { name: "No. of sewing line", set: "11 lines(In House)" },
    { name: "No. of sewing machines", set: "10 lines(Outsourced)" },
];