import React, { Component } from 'react';
import WhyusBreadcrumb from "../container/breadcrumb/WhyusBreadcrumb";
import WhatwedoContent from "../container/whyus/WhatwedoContent";
import CustomerLove from "../container/whyus/CustomerLove";
import WhatWeOffer from "../container/whyus/WhatWeOffer";
import StickyButton from "../container/StickyButton";

class WhyUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <WhyusBreadcrumb />
                <WhatwedoContent />
                <CustomerLove />
                <WhatWeOffer />
                <StickyButton />
            </React.Fragment>
        )
    }
}
export default WhyUs;
