import React, { Component } from 'react';

class WidgetMenu extends Component {
    render() {
        return (
            <div>
                <div className="widget-wrapper contact-us-widget">
                    <div className="widget-title">
                        <h4>{widget_address_data.widget_title}</h4>
                    </div>
                    <div className="widget-content widget-address-content">

                        <div className="single-address">
                            <div className="address-icon">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div className="address-content">
                                <p>+88 02 7789330</p>
                            </div>
                        </div>

                        <div className="single-address">
                            <div className="address-icon">
                                <i className="fa fa-envelope-o"></i>
                            </div>
                            <div className="address-content">
                                <p>info@bonianbd.com</p>
                            </div>
                        </div>


                        <div className="single-address">
                            <div className="address-icon">
                                <i className="fa fa-link" aria-hidden="true"></i>
                            </div>
                            <div className="address-content">
                                <p>www.bonianbd.com</p>
                            </div>
                        </div>

                        <div className="single-address">
                            <div className="address-icon">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                            </div>
                            <div className="address-content">
                                <a href="https://www.google.com/maps/place/Bonian+Knit+Fashion+Ltd./@23.9722247,90.2786064,17z/data=!3m1!4b1!4m5!3m4!1s0x3755e7ea0f5e2dbf:0x319f0e25d635a053!8m2!3d23.9722198!4d90.2807951?shorturl=1" target="_blank"><p>Latifpur, Shardagonj, <br /> Kashimpur, Gazipur, Bangladesh</p></a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default WidgetMenu;






// widget address content

const widget_address_data = {
    widget_title: "Contact us",
    widget_address: "6803 Dickens Islands Apt. 567",
    number: "(062) 109-9222",
    gmail: "contact@support.com"
}