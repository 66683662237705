import React from 'react'

const FooterBottom = () => {
    return (
        <div className="footer-bottom-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 text-center">
                        <p className="copy-right"> &copy; Bonian Knit Fashion Limited, All Rights Reserved. Developed with<i className="fa fa-heart"></i> by <a href="http://beatnik.technology/" target="_blank" rel="noopener noreferrer" > Beatnik Technology</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterBottom;
