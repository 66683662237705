import React, { Component, Fragment } from 'react';
// import Slider from "react-slick";
import CsrPageBreadcrumb from "../container/breadcrumb/CsrPageBreadcrumb";
import CustomScroll from "react-custom-scroll";
import "../../assets/css/customScroll.css";
import $ from "jquery";
import Axios from 'axios';
import { API_URL } from '../../utils/common';

class DemocsrPage extends Component {
    state = {
        data: [],
        singleData: []
    }
    bgContentHandler(data) {
        this.setState({
            singleData: { ...data }
        })
    }
    componentDidMount() {
        // same height
        function useSameHeight(el) {
            var max = 0;
            el.css('height', 'auto');
            el.each(function () {
                max = Math.max(max, $(this).height());
            }).height(max);
            return el;
        }
        $(window).resize(function () {
            useSameHeight($('.csr-inner-text-content p'));
        }).resize();
        // top loading
        window.scrollTo(0, 0)

        // Data fetch
        this.dataFetch()
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/csr`)
            .then(res => {
                this.setState({
                    data: res.data,
                    singleData: Object.keys(res.data).length !== 0 ? res.data[0] : {}
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        let { singleData, data } = this.state
        // csr thumbnail looping content
        return (
            <Fragment>
                <CsrPageBreadcrumb />
                <div className="csr-area-page section-padding">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                                <div className="section-title section-title-padding text-center">
                                    <h2>Corporate Social Responsibility</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {Object.keys(singleData).length !== 0 &&
                                    <div className="thumb-container">
                                        <div className="csr-large-image">
                                            <img src={API_URL + singleData.image} alt="BgImage" />
                                        </div>
                                        <div className="thumb-content-csr">
                                            <h3>{singleData.title}</h3>
                                            <p>{singleData.description}</p>
                                        </div>
                                    </div>}
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="csr-slider-area-right">
                                    <CustomScroll heightRelativeToParent="calc(100% - 150px)">
                                        {Object.keys(data).length !== 0 &&
                                            data.map((item, i) => (
                                                <div className="single-csr-thumbnail-content" key={i}>
                                                    <div className="csr-thumb">
                                                        <img src={API_URL + item.image} alt='ImageNotFound' onClick={() => this.bgContentHandler(item)} />
                                                    </div>
                                                    <div className="csr-thumb-content">
                                                        <h4 onClick={() => this.bgContentHandler(item)}>{item.title}</h4>
                                                        <p>{item.description.substring(0, 100)}</p>
                                                        <button onClick={() => this.bgContentHandler(item)} className="read-more">Read More</button>
                                                    </div>
                                                </div>
                                            ))}
                                    </CustomScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default DemocsrPage;