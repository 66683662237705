import React from 'react';

function WhyusBreadcrumb() {
    return (
        <div className="breadcrumb-area breadcrumb-padding why-us-breadcrumb">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="breadcrumb-content text-center">
                            <h2 className="text-white">Why Us</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WhyusBreadcrumb;