import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { API_URL } from './../../utils/common';
import Axios from 'axios';

// import md message file
import MdMessage from "../container/MdMessage";
// team section heading data
const team_section_data = {
    subheading: "Our management",
    heading: "Bonian Board of Directors"
}

class TopManagement extends Component {
    state = {
        data: []
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.dataFetch();
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/top-management`)
            .then(res => {
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        let { data } = this.state
        return (
            <React.Fragment>
                <MdMessage />
                <section className="team-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title text-center section-title-padding">
                                    <span>{team_section_data.subheading}</span>
                                    <h2>{team_section_data.heading}</h2>
                                </div>
                            </div>
                        </div>
                        {/* {get_member_data} */}
                        {Object.keys(data).length !== 0 &&
                            data.map(item => <Fade left key={item.id}>
                                <div className="row align-items-center single-team-member">
                                    <div className="col-lg-6 col-sm-6 col-md-6">
                                        <div className="bonian-team-member-img relative">
                                            <img src={API_URL + item.image} alt='TopManagement' />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-md-6">
                                        <div className="bonian-team-member-content">
                                            <h4 className="team-member-name">{item.name}</h4>
                                            <h5 className="team-member-designation">{item.job_title}</h5>
                                            <p>{item.description}</p>
                                            <div className="bonian-team-member-social-link social-link-group">
                                                <ul>
                                                    {item.facebook &&
                                                        <li>
                                                            <a href={item.facebook} target="_blank"><i className="fa fa-facebook"></i></a>
                                                        </li>
                                                    }
                                                    {item.linkedin &&
                                                        <li>
                                                            <a href={item.linkedin} target="_blank"><i className="fa fa-linkedin"></i></a>
                                                        </li>
                                                    }
                                                    {(item.id == 3) &&
                                                        <React.Fragment>
                                                            <li>
                                                                <a href="https://api.whatsapp.com/send?phone=8801750125811" target="_blank"><i className="fa fa-whatsapp"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="skype:<i.shetu1>?<action>" target="_blank"><i className="fa fa-skype"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="https://msng.link/o/?+8801750125811=wc" target="_blank"><i className="fa fa-weixin"></i></a>
                                                            </li>
                                                        </React.Fragment>
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>)}
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default TopManagement;



