import React, { Component } from 'react';
import ComplianceOfferingBread from '../container/breadcrumb/ComplinceOfferingBread';
import WelcomeCompliance from "../container/compliance/WelcomeCompliance";
import Partner from "../container/Partner";
import DownloadCertificate from "../container/compliance/DownloadCertificate";
import FireSafty from "../container/compliance/FireSafty";

class Compliance extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <ComplianceOfferingBread />
                <Partner />
                <WelcomeCompliance />
                <FireSafty />
                <DownloadCertificate />
            </React.Fragment>
        )
    }
}
export default Compliance