import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { API_URL } from '../../utils/common';
import Axios from 'axios';

class CounterUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            didViewCountUp: false
        }
    }
    componentDidMount() {
        this.dataFetch();
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/interesting`)
            .then(res => {
                console.log(res.data);
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }
    render() {
        let { data } = this.state
        return (
            <section className="interesting-fact-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-title section-title-padding text-center counter-up-heading-section">
                                <h2 className="text-white">Interesting Facts</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {Object.keys(data).length !== 0 &&
                            data.map(item => (
                                <div className="col-lg-3 col-md-6 col-sm-6 p-2" key={item.id}>
                                    <div className="single-fact">
                                        <div className="fact-icon">
                                            <img src={API_URL + item.image} alt="intersting fact" />
                                        </div>
                                        <div className="interesting-fact-content">
                                            <h4 className="count">
                                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                                    <CountUp end={this.state.didViewCountUp ? item.number : 0} />
                                                </VisibilitySensor>

                                            </h4>
                                            <h5 className="fact-name">{item.title}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        )
    }
}
export default CounterUp