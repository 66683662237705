import React, { Component } from 'react'

class Progress extends Component {
    render() {
        return (
            <div>   
               
            </div>
        )
    }
}
export default Progress;