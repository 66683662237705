import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { API_URL } from './../../../utils/common';

class WhatWeOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }
    componentDidMount() {
        this.dataFetch();
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/product`)
            .then(res => {
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        const { data } = this.state;
        return (
            <section className="what-we-offer-area section-padding">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-10 col-md-8 offset-lg-1 offset-md-2">
                            <div className="section-title section-title-padding text-center what-we-offer-section-title">
                                <h2>What we offer?</h2>
                                <p>BKFL provides a complete solution for knitwear products, from product design till exports.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {Object.keys(data).length !== 0 &&
                            data.map(item => <div className="col-lg-2 col-md-3 col-sm-6" key={item.id}>
                                <div className="what-we-offer-single-product">
                                    <img src={API_URL + item.image} alt='ProductImage' />
                                </div>
                            </div>)
                        }
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="view-all-product-content text-center mt-5">
                                <Link to="/products" className="theme-btn">View All Products</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default WhatWeOffer;
