import React, { Component, Fragment } from "react";
// import Slider from "react-slick";
import $ from "jquery";

class BonianGlance extends Component {
  state = {
    allCardData: [
      {
        glance_thumb: require("../../../../assets/images/bonian_glance/land.jpg"),
        glance_thumb_alt: "bonian glance thumbnail",
        name: "Total land area",
        value: "65.50 decimal",
      },

      {
        glance_thumb: require("../../../../assets/images/bonian_glance/staff.jpg"),
        glance_thumb_alt: "bonian glance thumbnail",
        name: "No. of staff",
        value: "70",
      },
      {
        glance_thumb: require("../../../../assets/images/bonian_glance/workers.jpg"),
        glance_thumb_alt: "bonian glance thumbnail",
        name: "No. of workers",
        value: "600",
      },
      {
        glance_thumb: require("../../../../assets/images/bonian_glance/space.jpg"),
        glance_thumb_alt: "bonian glance thumbnail",
        name: "Total working space:",
        value: "65,493.24 sq.ft",
      },
      {
        glance_thumb: require("../../../../assets/images/bonian_glance/wearhouse.jpg"),
        glance_thumb_alt: "bonian glance thumbnail",
        name: "Warehouse area:",
        value: "14,800 sq. ft",
      },

      {
        glance_thumb: require("../../../../assets/images/bonian_glance/energy.jpg"),
        glance_thumb_alt: "bonian glance thumbnail",
        name: "Energy:",
        value: "150 KV & 88 KV (2 Generators)",
      },
    ],
    singleCardData: {
      glance_thumb: require("../../../../assets/images/bonian_glance/land.jpg"),
      glance_thumb_alt: "bonian glance thumbnail",
      name: "Total land area",
      value: "65.50 decimal",
    },
  };
  bgContentHandler(data) {
    this.setState({
      singleCardData: { ...data },
    });
  }
  componentDidMount() {
    // same height
    function useSameHeight(el) {
      var max = 0;
      el.css("height", "auto");
      el.each(function () {
        max = Math.max(max, $(this).height());
      }).height(max);
      return el;
    }
    $(window)
      .resize(function () {
        useSameHeight($(".glance-inner-content h3"));
      })
      .resize();
    // top loading
    window.scrollTo(0, 0);
  }
  render() {
    let { singleCardData, allCardData } = this.state;
    // csr thumbnail looping content
    return (
      <Fragment>
        <div className="csr-area-page section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title section-title-padding text-center">
                  <h2>Bonian At a Glance</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="thumb-container">
                  <div className="csr-large-image">
                    <img src={singleCardData.glance_thumb} alt="BgImage" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  {Object.keys(allCardData).length !== 0 &&
                    allCardData.map((item, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        <div className="single-bonian-glance text-center">
                          <div
                            className="glance-inner-content"
                            onClick={() => this.bgContentHandler(item)}
                          >
                            <span>{item.name}</span>
                            <h3>{item.value}</h3>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BonianGlance;
