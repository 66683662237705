import React, { Component } from "react";
import ContactusBreadcrumb from "../container/breadcrumb/ContactusBreadcrumb";
import GoogleMap from "../container/GoogleMap";
import ContactForm from "./ContactForm";
// import we chat icon
const chaticon = {
  chat_icon: require("../../assets/images/we_chat.png"),
  chat_icon_alt: "chat icon",
};
class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    // contact page heading data
    let contact_page_data = {
      heading: "Contact Us",
      content: "",
    };
    // welcome contact page
    let welcome_contact_data = {
      heading: "Corporate Office",
    };
    //  contact grouping information
    let contact_grouping = {
      address: "House # 53, Sector #13, Road #14, Uttara",
      addres2: "Dhaka-1230, Bangladesh",
      phone: "+88 02 7789330",
      whatsapp: "+8801750125811",
      email: "info@bonianbd.com",
      chatapp: "+8801750125811",
      skype: "i.shetu1",
    };

    return (
      <React.Fragment>
        <ContactusBreadcrumb />
        <div className="contact-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12">
                <div className="section-title section-title-padding text-center contact-section-title">
                  <h2>{contact_page_data.heading}</h2>
                  <p>{contact_page_data.content}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="contact-page-address-wrapper">
                  <h3>{welcome_contact_data.heading}</h3>
                  <div className="contact-page-address-inner pt-4">
                    <ul>
                      <li>
                        <a
                          href="https://www.google.com/maps/place/Bonian+Corporate+Office/@23.8737624,90.3843698,17z/data=!3m1!4b1!4m5!3m4!1s0x3755c58bfcd2dad1:0xa1bd9c9cf73006d3!8m2!3d23.8737624!4d90.3865585"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="contact-info-text">
                            <span className="d-block">
                              {contact_grouping.address}
                            </span>
                            <span className="d-block">
                              {contact_grouping.addres2}
                            </span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="tel:+88 02 7789330"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i className="fa fa-phone" aria-hidden="true"></i>
                          </div>
                          <div className="contact-info-text">
                            <span>{contact_grouping.phone}</span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="mailto:info@bonianbd.com"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i
                              className="fa fa-envelope-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="contact-info-text">
                            <span>{contact_grouping.email}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="contact-page-address-wrapper mt-70">
                  <h3>If you have any inquiry please contact</h3>
                  <div className="contact-page-address-inner">
                    <ul>
                      <li>
                        <a
                          href="https://www.google.com/maps/place/Bonian+Corporate+Office/@23.8737624,90.3843698,17z/data=!3m1!4b1!4m5!3m4!1s0x3755c58bfcd2dad1:0xa1bd9c9cf73006d3!8m2!3d23.8737624!4d90.3865585"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-info-text">
                            <h6>
                              <span className="d-block">
                                <strong>Md. Moniruzzaman</strong>
                              </span>
                            </h6>
                            <span className="d-block">Executive Director</span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="tel:+88 02 7789330"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i className="fa fa-phone" aria-hidden="true"></i>
                          </div>
                          <div className="contact-info-text">
                            <span>8801750125811</span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=+8801750125811"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i
                              className="fa fa-whatsapp"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="contact-info-text">
                            <span>{contact_grouping.whatsapp}</span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="mailto:info@bonianbd.com"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i
                              className="fa fa-envelope-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="contact-info-text">
                            <span>moniruzzaman@bonianbd.com</span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://msng.link/o/?+8801750125811=wc"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <img
                              src={chaticon.chat_icon}
                              alt={chaticon.chat_icon_alt}
                            />
                          </div>
                          <div className="contact-info-text">
                            <span>{contact_grouping.chatapp}</span>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          href="skype:<i.shetu1>?<action>"
                          target="_blank"
                          className="contact-info-wrapper"
                        >
                          <div className="contact-icon-info">
                            <i className="fa fa-skype" aria-hidden="true"></i>
                          </div>
                          <div className="contact-info-text">
                            <span>i.shetu1</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
        <GoogleMap />
      </React.Fragment>
    );
  }
}
export default Contact;
