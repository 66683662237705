
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import Modal from 'react-responsive-modal';
import { API_URL } from './../../utils/common';
import Axios from 'axios';

// owl carousel product options
const options = {
    loop: true,
    autoplay: true,
    nav: false,
    mouseDrag: true,
    autoplayHoverPause: false,
    responsiveClass: true,
    dots: false,
    dragEndSpeed: true,
    smartSpeed: 250,
    autoplaySpeed: 250,
    autoplayTimeout: 1500,
    responsive: {
        0: {
            items: 2
        },
        700: {
            items: 3
        },
        786: {
            items: 4
        },
        1200: {
            items: 4
        }
    }
}

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            detail: {}
        }
    }
    componentDidMount() {
        this.dataFetch();
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/product`)
            .then(res => {
                console.log(res);
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        const { open, data, detail } = this.state;
        return (
            <section className="product-area home-page-product-content section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                            <div className="section-title text-center section-title-padding">
                                <h2>Our Products</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 relative">
                            <div className="product-wrapper-content">
                                <OwlCarousel
                                    className="owl-carousel owl-theme"
                                    {...options}
                                >
                                    {Object.keys(data).length !== 0 && data.map(item => (
                                        <div className="single-product text-center" key={item.id} onClick={() => this.setState({ open: true, detail: item })}>
                                            <div className="product-img">
                                                <img src={API_URL + item.image} alt="ProductImage" />
                                            </div>
                                            <div className="product-inner-content">
                                                <h5 className="bonian-product-name">{item.title}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <Link to={`${process.env.PUBLIC_URL}/products`} className="theme-btn mt-5">View All Products</Link>
                        </div>
                    </div>
                </div>
                {open &&
                    <Modal open={open} onClose={() => this.setState({ open: false, detail: {} })} center>
                        <div className="product-popup-description">
                            <div className="product-popup-img">
                                <img src={API_URL + detail.image} alt='ProductImage' />
                            </div>
                            <div className="product-popup-details">
                                <h5>{detail.title}</h5>
                                <p>Reference: <span>{detail.reference}</span></p>
                                <p>Fabrication: <span>{detail.fabrication}</span></p>
                                <p>Description: <span>{detail.description}</span></p>
                            </div>
                        </div>
                    </Modal>}
            </section>
        );
    }
}
export default Products;

