import React from "react";
import { Link } from "react-router-dom";

const MobileSticky = () => {
    return (
        <React.Fragment>
            <div className="sticky-btn-contact" id="mobile-sticky">
                <Link to={`${process.env.PUBLIC_URL}/contact`}>
                    Start A New Project
          </Link>
            </div>
        </React.Fragment>
    );
}
export default MobileSticky;
