import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_URL } from "../../../utils/common";
const WelcomeCompliance = () => {
  const [ctext, setCtext] = useState({});
  useEffect(() => {
    Axios.get(`${API_URL}api/compliance-text`).then((res) => {
      setCtext(res.data[0]);
    });
  }, []);
  return (
    <section className="compliance-area welcome-compliance-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-4 col-md-8 offset-md-2">
            <div className="compliance-wrapper">
              <div className="section-title bonian-welcome-text">
                <h2 className="text-white mb-3">{ctext.title}</h2>
                <p>{ctext.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WelcomeCompliance;

// all data
const all_data_compliance = {
  compliance_text:
    "The Compliance department is responsible for meeting all necessary guidelines required for the factory to meet in order to produce and export. We greatly emphasize fire, chemical, and electric safety. Regular fire and electric drills(training) are conducted to ensure safety. We facilitate a Worker’s Participation Committee(WPC) responsible for mediation between management and workers. We are GOTS, Accord, BSCI certified.",
};
