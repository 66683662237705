import React, { Component } from 'react';


// all data more info content
const more_info_why = {
    dedicated_team: [
        {
            more_icon: require("../../../assets/images/shipping/payment.png"),
            payment_info_heading: "Payment Terms",
            first_terms: "LC at Sight",
            second_terms: "TT Payment",
            banking_code: "SEBDBDDHBAN"
        }
    ],
    moq_time_line: [
        {
            moq_image: require("../../../assets/images/shipping/noun_coat.png"),
            moq_img_alt: "MOQImage",
            moq_heading: "MOQ’s & Timelines",
            moq_text_one: "Minimum Order Quantities (MOQs)",
            moq_feature_one: "Custom color / spec 3000 pcs",
            moq_text_two: "Timelines :",
            moq_feature_two: "Bangladesh – 8-10 weeks"
        }
    ],
    moq_shipping_mode: [
        {
            shipping_mode_img: require("../../../assets/images/shipping/noun_Shipping2.png"),
            moq_img_alt: "shippingImage",
            moq_heading: "Shipment Terms",
            shipping_mode_one: "Free on Board",
        }
    ]
}


class MoreInfoWhyUs extends Component {
    render() {
        return (
            <section className="more-info-why-us section-padding section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 small-screen">
                            {
                                more_info_why.dedicated_team.map((single_dedicated_team, i) => (
                                    <div className="quick-dedicated-team-content payment-info-wrapper  span-mode" key={i}>
                                        <div className="dedicated-img more-info-img payment-img">
                                            <img src={single_dedicated_team.more_icon} alt="sample image" />
                                        </div>
                                        <h3>{single_dedicated_team.payment_info_heading}</h3>
                                        <ul className="payment-terms">
                                            <li>{single_dedicated_team.first_terms}</li>
                                            <li>{single_dedicated_team.second_terms}</li>
                                        </ul>
                                        <p> <strong>Banking Details:</strong>Southeast Bank Limited, <br /> Banani Branch, Dhaka, Bangladesh</p>
                                        <p><strong>Swift Code:</strong>{single_dedicated_team.banking_code}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {
                                more_info_why.moq_time_line.map((single_moq, i) => (
                                    <div className="moq-content-area span-mode ml-5" key={i} id="moq-content">
                                        <div className="moq-img more-info-img">
                                            <img src={single_moq.moq_image} alt={single_moq.moq_img_alt} />
                                        </div>
                                        <h3>{single_moq.moq_heading}</h3>
                                        <p>{single_moq.moq_text_one}</p>
                                        <span>{single_moq.moq_feature_one}</span>
                                        <p>{single_moq.moq_text_two}</p>
                                        <span>{single_moq.moq_feature_two}</span>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="col-lg-4 col-md-6 small-screen">

                            {
                                more_info_why.moq_shipping_mode.map((single_shipping, i) => (
                                    <div className="shipping-mode-area span-mode" key={i} id="shipping-mode">
                                        <div className="shipping-img more-info-img">
                                            <img src={single_shipping.shipping_mode_img} alt={single_shipping.moq_img_alt} />
                                        </div>
                                        <h3>{single_shipping.moq_heading}</h3>
                                        <span>{single_shipping.shipping_mode_one}</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default MoreInfoWhyUs;