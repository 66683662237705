import React, { Component } from 'react';
import logo from "../../../assets/images/footer_logo.png";
import logoImg from "../../../assets/images/footer_logo_img.png";

class Logo extends Component {
    render() {
        return (
            <div className="widget-wrapper">
                <div className="widget-title widget-logo">
                    <div className="widget-logo-content">
                        <div className="footer-logo-top">
                            <img src={logoImg} alt="logoImage" />
                        </div>
                        <h4><img src={logo} alt="siteLogo" draggable="false" /></h4>
                        <span>100% Export Oriented Garments Industry</span>
                        <p className="dedicated-quality">Dedicated to Quality</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Logo;
