import React, { Component } from 'react';
import EcoFriendly from "../container/sustainability/EcoFriendly";
import SustainabilityBreadcrumb from "../container/breadcrumb/SustainabilityBreadcrumb";
import EcoManufacturingCycle from "../container/sustainability/EcoManufacturingCycle";
import SustainabilityLogoPortion from "../container/sustainability/SustainabilityLogoPortion";
import StickyButton from "../container/StickyButton";
import Responsible from '../container/sustainability/Responsible';

class Sustainability extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <SustainabilityBreadcrumb />
                <EcoFriendly />
                <Responsible/>
                {/* <SustainabilityLogoPortion /> */}
                <EcoManufacturingCycle />
                <StickyButton />
            </React.Fragment>
        )
    }
}
export default Sustainability;
