import React, { Component } from 'react';
import $ from "jquery";
import { Link } from "react-router-dom";
import { Tab, Row, Nav, Col } from "react-bootstrap";
import ProductBreadcrumb from "../container/breadcrumb/ProductBreadcrumb";
import Modal from 'react-responsive-modal';

import { API_URL } from './../../utils/common';
import Axios from 'axios';


class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            categories: [],
            detail: {}
        }
        this.dataFetch();
    }
    componentDidMount() {
        $(window).on('load', function () {
            // single product content height
            function useSameHeight(el) {
                var max = 0;
                el.css('height', 'auto');
                el.each(function () {
                    var c = this;
                    max = Math.max(max, $(c).height());
                }).height(max);
                return el;
            }
            $(window).resize(function () {
                useSameHeight($('.bonian-product-img'));
            }).resize();
        })
        // scroll top
        window.scrollTo(0, 0);
    }
    dataFetch = () => {
        Axios.get(`${API_URL}api/category`)
            .then(res => {
                this.setState({
                    categories: res.data,
                })
            })
            .catch(err => console.log('err', err))
        Axios.get(`${API_URL}api/product`)
            .then(res => {
                this.setState({
                    data: res.data,
                })
            })
            .catch(err => console.log('err', err))
    }
    render() {
        let { categories, data, open, detail } = this.state
        return (
            <React.Fragment>
                <ProductBreadcrumb />
                <section className="product-page-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title section-title-padding text-center">
                                    <h2>Our Products</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">

                                <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                                    <Row>
                                        <Col sm={4}>

                                            <Nav variant="pills" className="flex-column all-product-content-area single-product-widget">
                                                <h5 className="product-widget-heading text-center">Product Categories</h5>
                                                {Object.keys(categories).length !== 0 &&
                                                    categories.map(item =>
                                                        <Nav.Item className="product-category-item" id="men-product" key={item.id}>
                                                            <Nav.Link eventKey={item.id}>{item.name}</Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                                <Nav.Item className="product-category-item" id="all-product">
                                                    <Nav.Link eventKey="all">All</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={8}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="all" className="product-item-content">
                                                    <div className="row">
                                                        {Object.keys(data).length !== 0 &&
                                                            data.map(item => (
                                                                <div className="col-lg-4 col-sm-6 col-md-6" key={item.id}>
                                                                    <div className="bonian-single-product-content" onClick={() => this.setState({ open: true, detail: item })}>
                                                                        <div className="bonian-product-img">
                                                                            <img src={API_URL + item.image} alt='ProductImage' />
                                                                        </div>
                                                                        <div className="bonian-product-content">
                                                                            <h5 className="product-name">{item.title}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </Tab.Pane>

                                                {Object.keys(categories).length !== 0 &&
                                                    categories.map(cat =>
                                                        <Tab.Pane eventKey={cat.id} className="product-item-content" key={cat.id}>
                                                            <div className="row">
                                                                {Object.keys(data).length !== 0 &&
                                                                    data.map(item => cat.id === item.category_id &&
                                                                        <div className="col-lg-4 col-md-6" key={item.id}>
                                                                            <div className="bonian-single-product-content" onClick={() => this.setState({ open: true, detail: item })}>
                                                                                <div className="bonian-product-img">
                                                                                    <img src={API_URL + item.image} alt='ProductImage' />
                                                                                </div>
                                                                                <div className="bonian-product-content">
                                                                                    <h5 className="product-name">{item.title}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>)}
                                                            </div>
                                                        </Tab.Pane>)}
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fabrication-area section-padding section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title section-title-padding text-center">
                                    <h2>Types Of Fabrics We Work With</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="product-fabric-wrapper">
                                    <div className="single-fabrication" id="single-jersey">
                                        <div className="single-fabrication-content">
                                            <span>single jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="slub-jersey">
                                        <div className="single-fabrication-content">
                                            <span>Slub Jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="french-terry">
                                        <div className="single-fabrication-content">
                                            <span>French Terry</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="french-elastan">
                                        <div className="single-fabrication-content">
                                            <span>French Terry Elastan</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="jersey-elastan">
                                        <div className="single-fabrication-content">
                                            <span>Single Jersey Elastan </span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="needle-interlock">
                                        <div className="single-fabrication-content">
                                            <span>Drop Needle Interlock</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="design-rib">
                                        <div className="single-fabrication-content">
                                            <span>Deign Rib</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="tworib">
                                        <div className="single-fabrication-content">
                                            <span>2×1 Rib</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="onerib">
                                        <div className="single-fabrication-content">
                                            <span>1×1 Rib</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="viscose-jersey">
                                        <div className="single-fabrication-content">
                                            <span>Viscose Jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="viscose-elastan">
                                        <div className="single-fabrication-content">
                                            <span>Viscose  Elastan Jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="modal-jersey">
                                        <div className="single-fabrication-content">
                                            <span>Modal Jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="french-slub">
                                        <div className="single-fabrication-content">
                                            <span>French Terry Slub</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="waffle">
                                        <div className="single-fabrication-content">
                                            <span>Waffle</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="pique">
                                        <div className="single-fabrication-content">
                                            <span>Pique</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="double-lacoste">
                                        <div className="single-fabrication-content">
                                            <span>Double Lacoste</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="single-lacoste">
                                        <div className="single-fabrication-content">
                                            <span>Single Lacoste</span>
                                        </div>
                                    </div>


                                    <div className="single-fabrication" id="double-jersey">
                                        <div className="single-fabrication-content">
                                            <span>Double Jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="heavy-jersey">
                                        <div className="single-fabrication-content">
                                            <span>Heavy Jersey</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="fleece">
                                        <div className="single-fabrication-content">
                                            <span>Fleece</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="polar-fleece">
                                        <div className="single-fabrication-content">
                                            <span>Polar Fleece</span>
                                        </div>
                                    </div>

                                    <div className="single-fabrication" id="brushed">
                                        <div className="single-fabrication-content">
                                            <span>Brushed</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {open &&
                    <Modal open={open} onClose={() => this.setState({ open: false, detail: {} })} center>
                        <div className="product-popup-description">
                            <div className="product-popup-img">
                                <img src={API_URL + detail.image} alt='ProductImage' />
                            </div>
                            <div className="product-popup-details">
                                <h5>{detail.title}</h5>
                                <p>Reference: <span>{detail.reference}</span></p>
                                <p>Fabrication: <span>{detail.fabrication}</span></p>
                                <p>Description: <span>{detail.description}</span></p>
                            </div>
                        </div>
                    </Modal>}
            </React.Fragment>
        )
    }
}
export default ProductPage;